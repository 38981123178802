/* You can add global styles to this file, and also import other style files */
html,
body {
  padding: 0;
  margin: 0;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}